import { get, post } from '@/utils/api';

export default {
  state: () => ({
    availDest: null,
    availHotel: null,
    availNight: null,
    selectedDeals: null,
    searchData: null,
    /**
     * changed deal
     */
    changedAddedDeals: null,
    changedDeletedDeals: null,
    changedUpdatedDeals: null,
    isFileSendingState: false,
  }),
  getters: {
    GET_AVAILABLE_DESTINATION_FOR_MARKETER: (state) => state.availDest,
    GET_AVAILABLE_HOTELS_FOR_MARKETER: (state) => state.availHotel,
    GET_AVAILABLE_NIGHTS_FOR_MARKETER: (state) => state.availNight,
    GET_SELECTED_DEALS_FOR_MARKETER: (state) => state.selectedDeals,
    GET_SEARCH_INFORMATION: (state) => state.searchData,
    /**
     * changed deal
     */
    GET_CHANGED_DEALS_FOR_MARKETER: (state) => (payload) => {
      if (payload === 'added') {
        return state.changedAddedDeals;
      } else if (payload === 'deleted') {
        return state.changedDeletedDeals;
      } else {
        return state.changedUpdatedDeals;
      }
    },
    GET_IS_FILE_SENDING_STATE: (state) => state.isFileSendingState,
  },
  mutations: {
    SET_AVAILABLE_DESTINATION_FOR_MARKETER: (state, payload) => {
      state.availDest = payload;
    },
    SET_AVAILABLE_HOTELS_FOR_MARKETER: (state, payload) => {
      state.availHotel = payload;
    },
    SET_AVAILABLE_NIGHTS_FOR_MARKETER: (state, payload) => {
      state.availNight = payload;
    },
    SET_SELECTED_DEALS_FOR_MARKETER: (state, payload) => {
      state.selectedDeals = payload;
    },
    SET_SEARCH_INFORMATION: (state, payload) => {
      state.searchData = payload;
    },
    /**
     * changed deal
     */
    SET_CHANGED_DEALS_FOR_MARKETER: (state, payload) => {
      if (payload.type === 'added') {
        state.changedAddedDeals = payload.data;
      } else if (payload.type === 'deleted') {
        state.changedDeletedDeals = payload.data;
      } else {
        state.changedUpdatedDeals = payload.data;
      }
    },
    SET_IS_FILE_SENDING_STATE: (state, payload) => {
      state.isFileSendingState = payload;
    },
  },
  actions: {
    FETCH_AVAILABLE_DESTINATION_FOR_MARKETER: async (context) => {
      const { marketerId } = context.rootState.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `/?agency=${marketerId}` : '';
      try {
        const res = await get('BFF', `/pricePlan/availDests${paramMarketerId}`, context);
        context.commit('SET_AVAILABLE_DESTINATION_FOR_MARKETER', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    FETCH_AVAILABLE_HOTELS_FOR_MARKETER: async (context, payload) => {
      const { marketerId } = context.rootState.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      try {
        const res = await get('BFF', `/pricePlan/availHotels?destCode=${payload}${paramMarketerId}`, context);
        context.commit('SET_AVAILABLE_HOTELS_FOR_MARKETER', res.data);
        context.commit('SET_AVAILABLE_NIGHTS_FOR_MARKETER', null);
        return res.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    FETCH_AVAILABLE_NIGHTS_FOR_MARKETER: async (context, payload) => {
      const { marketerId } = context.rootState.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `&agency=${marketerId}` : '';
      const { dest, hotelId } = payload;
      try {
        const res = await get('BFF', `/pricePlan/availDurations?destCode=${dest}&hotelId=${hotelId}${paramMarketerId}`, context);
        context.commit('SET_AVAILABLE_NIGHTS_FOR_MARKETER', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    FETCH_SELECTED_DEALS_FOR_MARKETER: async (context) => {
      const { marketerId } = context.rootState.agencyUsers;
      const paramMarketerId = marketerId !== '' ? `/?agency=${marketerId}` : '';
      context.commit('SET_LOADING_STATE', true);
      const { dest, hotelId, nights, packStartDate } = context.state.searchData;
      const paramStartDate = (packStartDate) ? `&packStartDate=${packStartDate}` : '';
      try {
        const res = await get('BFF', `/pricePlan/${dest}/${hotelId}/${nights.join(',')}${paramMarketerId}${paramStartDate}`, context);
        context.commit('SET_SELECTED_DEALS_FOR_MARKETER', res.data);
        context.commit('SET_LOADING_STATE', false);
        return res.data;
      } catch (error) {
        context.commit('SET_LOADING_STATE', false);
        console.log(error);
        return null;
      }
    },
    /**
     * changed deal
     */
    FETCH_CHANGED_DEALS_FOR_MARKETER: async (context, payload) => {
      const { marketerId } = context.rootState.agencyUsers;
      const { type, changeId } = payload;
      const paramMarketerId = marketerId !== '' ? `/?agency=${marketerId}` : '';
      const paramChangeId = changeId !== '' ? `&changeId=${changeId}` : '';
      context.commit('SET_LOADING_STATE', true);
      try {
        const res = await get('BFF', `/pricePlan/changes/${type}${paramMarketerId}${paramChangeId}`, context);
        context.commit('SET_CHANGED_DEALS_FOR_MARKETER', { type, data: res.data });
        context.commit('SET_LOADING_STATE', false);
        return res.data;
      } catch (error) {
        context.commit('SET_LOADING_STATE', false);
        console.log(error);
        return null;
      }
    },
    SEND_PRICE_PLAN_FILE_TO_WHATSAPP: async (context, payload) => {
      const { exportFile } = payload;
      const formData = new FormData();
      formData.append('exportFile', exportFile, 'exportFile.html');
      context.commit('SET_IS_FILE_SENDING_STATE', true);
      try {
        const res = await post('BFF', '/pricePlan/send', formData, context, '', true);
        context.commit('SET_IS_FILE_SENDING_STATE', false);
        return res.data;
      } catch (error) {
        return null;
      }
    },
  },
};
