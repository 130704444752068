// import dayjs from 'dayjs';
import { sortDeal } from '@/utils/bonauf/controllerCommon';

const allDeals = 'wednesday', mainSubcategories = ['deals', 'Most View', 'The hottest'];
const extraDeals = 'wednesday', exceptSubcategory = ['638467d2eebca0531543437d'];

function getFilteredDeals(pPackage, pOption) {
  let deals = [];
  const { destination, category, month } = pOption;
  const objPackages = pPackage?.packages ?? {};
  Object.entries(objPackages).forEach(([key, value]) => {
    if (category === key || category === null) {
      mainSubcategories.forEach((mainSubcategory) => {
        deals = deals.concat(value[allDeals]?.[mainSubcategory]?.filter((deal) => {
          const isMatchDest = destination !== null ? destination === deal.dest : true;
          const isMatchMonth = month !== null ? Number(month) === deal.month : true;
          return isMatchDest && isMatchMonth;
        }));
      });

      // const dealsWed = value[allDeals]?.deals?.filter((deal) => {
      //   const isMatchDest = destination !== null ? destination === deal.dest : true;
      //   const isMatchMonth = month !== null ? Number(month) === deal.month : true;
      //   return isMatchDest && isMatchMonth;
      // });
      // dealsWed.forEach((deal) => {
      //   if (deal) {
      //     const idx = deals.findIndex((tarDeal) => tarDeal?.link === deal?.link);
      //     if (idx === -1) deals.push(deal);
      //   }
      // });
    }
  });
  return deals.filter((deal) => deal !== null && deal !== undefined);
}

function getNonemptyCategories(pPackage, pOption) {
  const categories = [];
  const { destination, month } = pOption;
  const objPackages = pPackage?.packages ?? {};
  Object.entries(objPackages).forEach(([key, value]) => {
    let deals = [];
    mainSubcategories.forEach((mainSubcategory) => {
      deals = deals.concat(value[allDeals]?.[mainSubcategory]?.filter((deal) => {
        const isMatchDest = destination !== null ? destination === deal.dest : true;
        const isMatchMonth = month !== null ? Number(month) === deal.month : true;
        return isMatchDest && isMatchMonth;
      }));
    });

    if(deals.filter((deal) => deal !== null && deal !== undefined).length > 0) {
      categories.push(key);
    }
  });

  return categories;
}


function getDestOptionsDealPackages(pDeals, pPackage) {
  const destList = [{ value: null, text: 'לאן תרצו לטוס הפעם?' }];
  // if (pDeals.length > 0) {
  //   const dests = pDeals.reduce((prevList, currDeal) => {
  //     if (!prevList.includes(currDeal.dest)) prevList.push(currDeal.dest);
  //     return prevList;
  //   }, []);
  //   const objPackages = pPackage?.filters?.dest ?? {};
  //   Object.entries(objPackages).forEach(([key, value]) => {
  //     if (dests.includes(key)) destList.push({ value: key, text: value });
  //   });
  // }
  const tmpList = [];
  const objPackages = pPackage?.filters?.dest ?? {};
  Object.entries(objPackages).forEach(([key, value]) => {
    tmpList.push({ value: key, text: value });
  });
  tmpList.sort((a, b) => {
    if (a.text === b.text) {
      return 0;
    }
    return a.text < b.text ? -1 : 1;
  });
  destList.push(...tmpList);
  return destList;
}

function getMonthOptionsDealPackages(pDeals, pPackage) {
  const optionsMonth = [{ value: null, text: 'חודש היציאה' }];
  if (pDeals.length > 0) {
    const months = pDeals.reduce((prevList, currDeal) => {
      if (!prevList.includes(currDeal.month)) prevList.push(currDeal.month);
      return prevList;
    }, []);
    const objMonths = pPackage?.filters?.months ?? {};
    Object.entries(objMonths).forEach(([key, value]) => {
      if (months.includes(Number(key))) optionsMonth.push({ value: Number(key), text: value });
    });
  }
  return optionsMonth;
}

function getCategoryOptionsDealPackages(pDeals, pMenu) {
  const optionsCategory = [{ value: null, text: 'בחר קטגוריה' }];
  if (pDeals.length > 0) {
    const categories = pDeals.reduce((prevList, currDeal) => {
      if (!prevList.includes(currDeal.category)) prevList.push(currDeal.category);
      return prevList;
    }, []);
    const objMenus = pMenu ?? [];
    objMenus.forEach((menu) => {
      if (categories.includes(menu.code)) {
        optionsCategory.push({ value: menu.code, text: menu.title });
      }
    });
  }
  return optionsCategory;
}

function getRangePriceDeals(pDeals) {
  const range = pDeals?.reduce((prevRange, deal) => {
    if (deal) {
      if (prevRange.min > deal.price_int) prevRange.min = deal?.price_int;
      if (prevRange.max < deal.price_int) prevRange.max = deal?.price_int;
    }
    return prevRange;
  }, { min: 100000, max: 0 });
  return range;
}

function getBatchDealPerHotel(pDeals) {
  let batchDeal = {};
  if (pDeals.length > 0) {
    batchDeal = pDeals.reduce((prev, deal) => {
      const batchId = deal.packageCategory === 'Flight_Only' ? deal.dest : deal.hotelId;
      if (prev[batchId] === undefined) {
        prev[batchId] = [deal];
      } else {
        prev[batchId].push(deal);
      }
      return prev;
    }, {});
  }
  return batchDeal;
}

function getSortedDealsPerHotel(pDeals) {
  const batchDeal = getBatchDealPerHotel(pDeals);
  const list = [];
  Object.entries(batchDeal).forEach(([, batch]) => {
    // group batch deals by start date
    const groupedByStartDate = Object.entries(batch.reduce((items, current) => {
      const date = current.packStartDate;
      if (!items[date]) {
        items[date] = [];
      }
      items[date].push(current);
      return items;
    }, {})).map(([, items]) => items)
    .sort((a, b) => new Date(a[0].packStartDate) - new Date(b[0].packStartDate));

    // sort each group by duration and price
    groupedByStartDate.forEach((items) => {
      items.sort(sortDeal);
    })


    // add group to list
    list.push(groupedByStartDate);
  });

  // sort list by priority
  list.sort((a, b) => {
    let priority = a[0][0].priority - b[0][0].priority;
    if (priority === 0) {
      priority = a[0][0].price_int - b[0][0].price_int;
    }
    return priority;
  });
  return list;
}

function estimationAvailableSubcategory(pPackage, pSubcategories, pOption) {
  const { category } = pOption;
  let avail = false;
  pSubcategories.forEach((subcate) => {
    if (!exceptSubcategory.includes(subcate.id)) {
      if (pPackage?.packages?.[category]?.[extraDeals]?.[subcate.title]?.length > 0) avail = true;
    }
  });
  return avail;
}
function getAppDealsUnderCategory(pPackage, pOptions) {
  const deals = pPackage.packages?.[pOptions.codeCategory]?.[extraDeals]?.[pOptions.codeSubcategory] ?? [];
  return deals;
}
function getActiveSubcategories(pCateCode, pAppCategories, pPackage, pSubcategories) {
  const listSubcategory = [];
  pSubcategories.forEach((subcate) => {
    if (!exceptSubcategory.includes(subcate.id)) {
      if (pPackage.packages?.[pCateCode]?.[extraDeals]?.[subcate.title]?.length > 0) {
        const activeCate = pAppCategories.find((cate) => cate.code === pCateCode);
        if (activeCate) {
          listSubcategory.push({
            value: subcate.title,
            text: activeCate.subCategLabelById?.[subcate.id] ?? subcate.title,
          });
        }
      }
    }
  });
  return listSubcategory.sort((subcate1, subcate2) => subcate1.order - subcate2.order);
}

export {
  getFilteredDeals,
  getNonemptyCategories,
  estimationAvailableSubcategory,
  getDestOptionsDealPackages,
  getMonthOptionsDealPackages,
  getCategoryOptionsDealPackages,
  getRangePriceDeals,
  getBatchDealPerHotel,
  getSortedDealsPerHotel,
  getAppDealsUnderCategory,
  getActiveSubcategories,
};
