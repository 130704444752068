import {
// get,
  post,
  utf8ToB64,
  b64ToUtf8,
} from '@/utils/api';

const passengerTypeList = {
  adult: 'ADT',
  child: 'CNN',
  senior: 'ADT',
  baby: 'INF',
};
export default {
  state: () => ({
    foBookingState: '',
    orderedFlight: null,
    brandFeatureDescs: null,
    baggageChargeDescs: null,
    orderedCondition: null,
    bookingRoutersList: ['fo-booking-passenger', 'fo-booking-additional-service', 'fo-booking-payer', 'fo-booking-flights', 'fo-notify-card-completion'],

    bookingItineraryInfo: null,
    bookingPassengerInfo: null,
    // bookingServiceInfo: null,
    bookingPayerInfo: null,
    bookingPaymentUrl: '',
    bookingPNR: '',
    bookingTransId: null,
    approvalCondition: null,
    searchQuery: '',

    isFCSupplied: false,
    priceInfo: null,

    ticketBrandInfo: 0,
    baggageInfo: null,
    seatsOriginalData: [],
    seatCabinInfo: null,
    selectedSeatData: [],
    revalidateData: null,

    bookingResponse: null,
    bookingCardCompletionInfo: null,
  }),
  getters: {
    GET_FO_BOOKING_STATE: (state) => state.foBookingState,
    GET_FO_BOOKING_ROUTERS: (state) => state.bookingRoutersList,
    GET_FO_IS_FC_SUPPLIED: (state) => state.isFCSupplied,

    GET_SABRE_ORDERED_FLIGHT: (state) => state.orderedFlight,
    GET_SABRE_BOOKING_BRAND_DESC: (state) => state.brandFeatureDescs,
    GET_SABRE_BOOKING_BAGGAGE_DESC: (state) => state.baggageChargeDescs,
    GET_SABRE_ORDERED_CONDITION: (state) => state.orderedCondition,
    GET_SABRE_BOOKING_FLIGHT_SEARCH_QUERY: (state) => state.searchQuery,
    GET_SABRE_PASSENGER_DATA: (state) => state.bookingPassengerInfo,
    // GET_SABRE_SERVICE_DATA: (state) => state.bookingServiceInfo,
    GET_SABRE_PAYER_INFO: (state) => state.bookingPayerInfo,
    GET_SABRE_PAYMENT_URL: (state) => state.bookingPaymentUrl,
    GET_SABRE_BOOKING_PNR: (state) => state.bookingPNR,
    GET_SABRE_BOOKING_TRANS_ID: (state) => state.bookingTransId,
    GET_SABRE_APPROVAL_CONDITION: (state) => state.approvalCondition,

    GET_FO_SABRE_BOOKING_PRICE_INFO: (state) => state.priceInfo,
    GET_SABRE_FO_BOOKING_TICKET_BRAND_DATA: (state) => state.ticketBrandInfo,
    // GET_SABRE_FO_BOOKING_BAGGAGE_DATA: (state) => state.baggageInfo,
    GET_SABRE_FO_BOOKING_SEATS_ORIGINAL_DATA: (state) => state.seatsOriginalData,
    GET_SABRE_FO_BOOKING_SEATS_CABIN_INFO: (state) => state.seatCabinInfo,
    GET_SABRE_FO_BOOKING_SELECTED_SEAT_DATA: (state) => state.selectedSeatData,
    GET_SABRE_FO_BOOKING_REVALIDATE_DATA: (state) => state.revalidateData,
    GET_SABRE_FO_BOOKING_INFO: (state) => state.bookingResponse,

    GET_SABRE_FO_BOOKING_CARD_COMPLETION_INFO: (state) => state.bookingCardCompletionInfo,
  },
  mutations: {
    SET_FO_BOOKING_STATE: (state, payload) => {
      state.foBookingState = payload;
    },
    SET_FO_IS_FC_SUPPLIED: (state, payload) => {
      state.isFCSupplied = payload;
    },
    SET_SABRE_ORDERED_FLIGHT: (state, payload) => {
      state.orderedFlight = payload;
    },
    SET_SABRE_BOOKING_ITINERARY_INFO: (state, payload) => {
      if (!payload) {
        state.bookingItineraryInfo = null;
        return;
      }
      const itinerary = {
        totalPrice: payload.excerpt.totalPrice,
        currency: payload.excerpt.currency,
      };

      let flightId = -1;
      const outwardArray = [];
      const returningArray = [];

      const flights = payload.legs.map((leg, inx) => leg.segmentExcerpts.map((segment) => {
        const arrivalDate = new Date(segment.departureDate);
        arrivalDate.setDate(arrivalDate.getDate() + +segment.arrival.dateAdjustment);
        const calcDate = arrivalDate.toISOString().split('T')[0];

        flightId += 1;
        if (inx === 0) outwardArray.push(flightId);
        if (inx === 1) returningArray.push(flightId);
        return {
          id: flightId,
          airlineCode: segment.airline,
          flightNumber: `${segment.flightNumber}`,
          originLocationCode: segment.departure.city || segment.departure.airport,
          destinationLocationCode: segment.arrival.city || segment.arrival.airport,
          departureDateTime: `${segment.departureDate}T${segment.departure.time.substr(0, 8)}`,
          arrivalDateTime: `${calcDate}T${segment.arrival.time.substr(0, 8)}`,
          bookingClassCode: leg.excerpt.cabinCode,
        };
      })).flat();

      itinerary.flights = flights;
      itinerary.outwardFlights = outwardArray;
      itinerary.returningFlights = returningArray;

      state.bookingItineraryInfo = itinerary;
    },
    SET_SABRE_BOOKING_BRAND_DESC: (state, payload) => {
      state.brandFeatureDescs = payload;
    },
    SET_SABRE_BOOKING_BAGGAGE_DESC: (state, payload) => {
      state.baggageChargeDescs = payload;
    },
    SET_SABRE_BOOKING_SEARCH_QUERY: (state, payload) => {
      state.searchQuery = payload;
    },
    SET_SABRE_ORDERED_CONDITION: (state, payload) => {
      state.orderedCondition = payload;
    },
    SET_SABRE_BOOKING_PASSENGER_INFO: (state, payload) => {
      state.bookingPassengerInfo = payload;
    },
    // SET_SABRE_BOOKING_SERVICE_INFO: (state, payload) => {
    //   state.bookingServiceInfo = payload;
    // },
    SET_SABRE_PAYER_DATA: (state, payload) => {
      state.bookingPayerInfo = payload;
    },
    SET_SABRE_BOOKING_PAYMENT_URL: (state, payload) => {
      state.bookingPaymentUrl = payload;
    },
    SET_SABRE_BOOKING_PNR: (state, payload) => {
      state.bookingPNR = payload;
    },
    SET_SABRE_BOOKING_TRANS_ID: (state, payload) => {
      state.bookingTransId = payload;
    },
    SET_SABRE_APPROVAL_CONDITION: (state, payload) => {
      state.approvalCondition = payload;
    },

    SET_FO_SABRE_BOOKING_PRICE_INFO: (state, payload) => {
      const { type, price } = payload;
      const storedPrice = state.priceInfo || {};
      if (type === 'brand') {
        storedPrice.brandPrice = price?.totalPrice || 0;
        storedPrice.brandCurrency = price?.currency || 0;
        storedPrice.totalPrice = price?.totalPrice || 0;
      }
      if (type === 'baggage') { storedPrice.baggage = price; }
      if (type === 'seat') { storedPrice.seat = price; }

      state.priceInfo = storedPrice;
      state.priceInfo.totalPrice = (storedPrice?.brandPrice || 0) + (storedPrice?.baggage || 0) + (storedPrice?.seat || 0);
    },
    SET_SABRE_FO_BOOKING_TICKET_BRAND_DATA: (state, payload) => {
      state.ticketBrandInfo = payload;
    },
    SET_SABRE_FO_BOOKING_BAGGAGE_DATA: (state, payload) => {
      const { orderedFlight } = state;
      const outCheckinBaggageAllowance = orderedFlight.legs[0]?.excerpt.checkinBaggageAllowance || null;
      const returnCheckinBaggageAllowance = orderedFlight.legs[1]?.excerpt.checkinBaggageAllowance || null;

      const checkinBaggages = payload.data.map((baggage) => {
        if (baggage.isOneWay) {
          return {
            passengerRefId: baggage.passengerRefId,
            returningLeg: false,
            checkinBaggage: {
              byPieces: outCheckinBaggageAllowance?.byPieces,
              pieceCount: baggage.outCount,
              weight: outCheckinBaggageAllowance?.weight,
              unit: outCheckinBaggageAllowance?.unit,
              charge: baggage.outPrice,
            },
          };
        }
        return [
          {
            passengerRefId: baggage.passengerRefId,
            returningLeg: false,
            checkinBaggage: {
              byPieces: outCheckinBaggageAllowance?.byPieces,
              pieceCount: baggage.outCount,
              weight: outCheckinBaggageAllowance?.weight,
              unit: outCheckinBaggageAllowance?.unit,
              charge: baggage.outPrice,
            },
          },
          {
            passengerRefId: baggage.passengerRefId,
            returningLeg: true,
            checkinBaggage: {
              byPieces: returnCheckinBaggageAllowance?.byPieces,
              pieceCount: baggage.returnCount,
              weight: returnCheckinBaggageAllowance?.weight,
              unit: returnCheckinBaggageAllowance?.unit,
              charge: baggage.returnPrice,
            },
          },
        ];
      }).flat();

      state.baggageInfo = {
        checkinBaggages,
        chargeAmount: payload.totalPrice,
      };
    },
    SET_SABRE_FO_BOOKING_SEATS_ORIGINAL_DATA: (state, payload) => {
      const { seatDataKey, data } = payload;
      const orgSeatData = [...state.seatsOriginalData];
      data.seatDataKey = seatDataKey;
      const curInx = orgSeatData.findIndex((seat) => seat.seatDataKey === seatDataKey);
      if (curInx > -1) {
        orgSeatData[curInx] = data;
      } else {
        orgSeatData.push(data);
      }
      state.seatsOriginalData = orgSeatData;
    },
    SET_SABRE_FO_BOOKING_SEATS_CABIN_INFO: (state, payload) => {
      state.seatCabinInfo = payload;
    },
    SET_SABRE_FO_BOOKING_SELECTED_SEAT_DATA: (state, payload) => {
      const { passengerIndex, flightIndex, segmentIndex, remove } = payload;

      const seatData = [...state.selectedSeatData];
      const index = seatData.findIndex((seat) => seat.passengerIndex === passengerIndex && seat.flightIndex === flightIndex && seat.segmentIndex === segmentIndex);

      if (index === -1) {
        seatData.push(payload);
      } else if (remove) {
        seatData.splice(index, 1);
      } else {
        seatData.splice(index, 1, payload);
      }
      state.selectedSeatData = seatData;
    },
    INITIALIZE_SELECTED_SEAT_DATA: (state) => {
      state.seatsOriginalData = [];
      state.selectedSeatData = [];
    },
    SET_SABRE_FO_SERVICE_DATA_FROM_STORE: (state, payload) => {
      const { ticketBrandInfo, baggageInfo, seatsOriginalData, selectedSeatData } = payload;
      state.baggageInfo = baggageInfo;
      state.ticketBrandInfo = ticketBrandInfo;
      state.seatsOriginalData = seatsOriginalData || [];
      state.selectedSeatData = selectedSeatData;
    },
    SET_SABRE_FO_BOOKING_REVALIDATE_DATA: (state, payload) => {
      state.revalidateData = payload?.data || null;
    },
    SET_SABRE_FO_BOOKING_FLIGHT_INFO: (state, payload) => {
      state.bookingResponse = payload;
    },
    SET_SABRE_BOOKING_CARD_COMPLETION: (state, payload) => {
      state.bookingCardCompletionInfo = payload;
    },
  },
  actions: {
    FETCH_SABRE_ORDERED_INFORMATION: (context) => {
      const flight = window.localStorage.getItem('sabreOrderedFlight');
      const orderedFlight = flight ? JSON.parse(b64ToUtf8(flight)) : null;

      const condition = window.localStorage.getItem('sabreSearchCondition');
      const orderedCondition = condition ? JSON.parse(b64ToUtf8(condition)) : null;

      const searchQuery = window.localStorage.getItem('sabreSearchQuery');
      const searchQueryString = searchQuery ? b64ToUtf8(searchQuery) : null;

      const brandFeatures = window.localStorage.getItem('sabreBrandFeatureDescs');
      const brandFeaturesDesc = brandFeatures ? JSON.parse(b64ToUtf8(brandFeatures)) : null;

      const baggageCharges = window.localStorage.getItem('sabreBaggageChargeDescs');
      const baggageChargesDescs = baggageCharges ? JSON.parse(b64ToUtf8(baggageCharges)) : null;

      if (orderedFlight) {
        context.commit('SET_SABRE_ORDERED_FLIGHT', orderedFlight);
        context.commit('SET_FO_SABRE_BOOKING_PRICE_INFO', { type: 'brand', price: orderedFlight.excerpt });
      } else {
        context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'There is no stored flight info. Try the search operation again.');
      }

      context.commit('SET_SABRE_BOOKING_ITINERARY_INFO', orderedFlight);
      context.commit('SET_FO_IS_FC_SUPPLIED', orderedFlight?.itinInfo?.fcSupplied || false);
      context.commit('SET_SABRE_BOOKING_SEARCH_QUERY', searchQueryString);

      context.commit('SET_SABRE_BOOKING_BRAND_DESC', brandFeaturesDesc);
      context.commit('SET_SABRE_BOOKING_BAGGAGE_DESC', baggageChargesDescs);

      context.commit('SET_SABRE_ORDERED_CONDITION', orderedCondition);
    },
    STORE_SABRE_PASSENGER_DATA: (context, payload) => {
      window.localStorage.setItem('sabrePassengerData', utf8ToB64(JSON.stringify(payload)));
      context.commit('SET_SABRE_BOOKING_PASSENGER_INFO', payload);
    },
    TAKE_OUT_SABRE_PASSENGER_DATA: (context) => {
      const data = window.localStorage.getItem('sabrePassengerData');
      const passengerData = data ? JSON.parse(b64ToUtf8(data)) : null;

      context.commit('SET_SABRE_BOOKING_PASSENGER_INFO', passengerData);
    },
    STORE_SABRE_SERVICE_DATA: (context) => {
      const { ticketBrandInfo, baggageInfo, seatsOriginalData, selectedSeatData } = context.state;
      const saveData = { ticketBrandInfo, baggageInfo, seatsOriginalData, selectedSeatData };
      window.localStorage.setItem('sabreServiceData', utf8ToB64(JSON.stringify(saveData)));
      // context.commit('SET_SABRE_BOOKING_SERVICE_INFO', payload);
    },
    TAKE_OUT_SABRE_SERVICES: (context) => {
      const data = window.localStorage.getItem('sabreServiceData');

      const service = data ? JSON.parse(b64ToUtf8(data)) : null;

      if (service)context.commit('SET_SABRE_FO_SERVICE_DATA_FROM_STORE', service);
      // context.commit('SET_SABRE_BOOKING_SERVICE_INFO', service);
    },
    // FETCH_SABRE_PAYMENT_URL: async (context, payload) => {
    //   const { payer, condition } = payload;
    //   const url = '/operation/aggrSabre/book';

    //   try {
    //     context.commit('SET_LOADING_STATE', true);
    //     // const response = await post('BFF', url, payer, context);
    //     // const response = 'https://www.credit2000.co.il/pci_emv_ver2/redirect/Flying_1280_tyco/credit2000he.aspx?params=710d50ec-22b5-4d3d-b5e7-e07912241785&css=https://flying.co.il/assets/css/styleVerify.css?ver=1.3';
    //     // if (response) {
    //     //   context.commit('SET_SABRE_BOOKING_PAYMENT_URL', response);
    //     // } else {
    //     //   context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'Error to fetching payment url.');
    //     // }
    //     context.commit('SET_SABRE_PAYER_DATA', payer);
    //     context.commit('SET_SABRE_APPROVAL_CONDITION', condition);
    //     context.commit('SET_LOADING_STATE', false);

    //     window.localStorage.setItem('sabrePayerConditionInfo', utf8ToB64(JSON.stringify(payload)));
    //     // return response;
    //     return '';
    //   } catch (error) {
    //     const body = `<p>${JSON.stringify(payload)}</p>
    //     <h3>${url}</h3></Br>
    //     <pre>${error.response.data.title || error.response.data}</pre>`;
    //     const notification = {
    //       subject: `Sabre flight get payment url - ${error.response.status}`,
    //       body,
    //     };
    //     context.dispatch('NOTIFY_ADMIN', notification);
    //     context.commit('SET_LOADING_STATE', false);
    //     return null;
    //   }
    // },
    TAKE_OUT_SABRE_PAYER_CONDITION_INFO: (context) => {
      const data = window.localStorage.getItem('sabrePayerConditionInfo');
      const parsingData = data ? JSON.parse(b64ToUtf8(data)) : null;

      context.commit('SET_SABRE_PAYER_DATA', parsingData?.payer || null);
      context.commit('SET_SABRE_APPROVAL_CONDITION', parsingData?.condition || null);
    },

    FETCH_SABRE_FO_SEATS_ORIGINAL_DATA: async (context, payload) => {
      const { passengerIndex, flightIndex, segmentIndex } = payload;
      const { orderedFlight, bookingPassengerInfo, ticketBrandInfo, seatsOriginalData } = context.state;

      const flightCount = orderedFlight.legs.length;
      const segmentsCount = orderedFlight.legs[flightIndex].segmentExcerpts.length;

      const seatDataKey = `${passengerIndex}-${flightIndex}-${segmentIndex}`;
      if (seatsOriginalData && seatsOriginalData.findIndex((seat) => seat.seatDataKey === seatDataKey) > -1) return false;
      if (ticketBrandInfo < 0) return false;

      const passenger = bookingPassengerInfo[passengerIndex] || null;
      const passengerType = passengerTypeList[passenger?.passengerType] || 'ADT';
      const matchedPricingInformation = orderedFlight?.pricingInformation[ticketBrandInfo]?.fare?.passengerInfoList
        .find((passengerInfo) => passengerInfo.passengerType.toLowerCase() === passenger.passengerType || passengerInfo.passengerType.toUpperCase() === passengerType) || null;

      const { bookingCode, cabinCode } = (matchedPricingInformation?.fareComponents.length === flightCount * segmentsCount)
        ? matchedPricingInformation?.fareComponents[flightIndex + segmentIndex]?.segments[0] || {}
        : matchedPricingInformation?.fareComponents[flightIndex]?.segments[segmentIndex] || {};

      const segment = orderedFlight?.legs[flightIndex]?.segmentExcerpts[segmentIndex] || null;

      const departureDate = `${segment?.departureDate}T${segment?.departure?.time}`;
      const arrivalOnlyDate = new Date(segment?.departureDate);
      arrivalOnlyDate.setDate(arrivalOnlyDate.getDate() + +segment.arrival.dateAdjustment);
      const arrivalDate = `${arrivalOnlyDate.toISOString().slice(0, 10)}T${segment?.arrival?.time}`;

      const requestBody = {
        flightSegment: {
          originAirportIataCode: segment?.departure?.airport || '',
          destinationAirportIataCode: segment?.arrival?.airport || '',
          departureDateTime: departureDate.slice(0, 19),
          arrivalDateTime: arrivalDate.slice(0, 19),
          marketing: {
            airlineCode: segment?.carrier?.marketing || '',
            flightNumber: `${segment?.carrier?.marketingFlightNumber}` || '',
          },
          operating: {
            airlineCode: segment?.carrier?.operating || '',
            flightNumber: `${segment?.carrier?.operatingFlightNumber}` || '',
          },
        },
        bookingCode: bookingCode || '',
        passengers: [passenger],
      };

      try {
        context.commit('SET_LOADING_STATE', true);
        const response = await post('BFF', '/operation/aggrSabre/seatMap', requestBody, context);

        if (response?.data?.error?.message) {
          context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', response?.data?.error?.message || '');

          const body = `<p>${JSON.stringify(requestBody)}</p>
          <pre>${response.data.error.message}</pre>`;

          const notification = {
            subject: 'No data in Sabre flight getting seat data',
            body,
          };
          context.dispatch('NOTIFY_ADMIN', notification);
        } else if (response?.data?.data?.simplifiedSeatMap) {
          context.commit('SET_SABRE_FO_BOOKING_SEATS_ORIGINAL_DATA', { seatDataKey, data: response.data.data });
          context.commit('SET_SABRE_FO_BOOKING_SEATS_CABIN_INFO', { bookingCode, cabinCode });
        }
        // if (response?.data?.data?.simplifiedSeatMap) {
        //   context.commit('SET_SABRE_FO_BOOKING_SEATS_ORIGINAL_DATA', { seatDataKey, data: response.data.data });
        // } else if (response?.data?.error?.message) {
        //   context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', response?.data?.error?.message || '');
        // }
        context.commit('SET_LOADING_STATE', false);
        return response.data;
      } catch (error) {
        const body = `<p>${JSON.stringify(requestBody)}</p>
        <pre>${error?.response?.data?.title || ''}</pre>
        <pre>${error?.response?.data && JSON.stringify(error?.response?.data)}</pre>`;

        const notification = {
          subject: `Sabre flight getting seat data  - ${error?.response?.status || ''}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);
        context.commit('SET_LOADING_STATE', false);
        return null;
      }
    },
    SABRE_FO_REVALIDATE: async (context) => {
      const { orderedFlight, orderedCondition, ticketBrandInfo } = context.state;

      const flightCount = orderedFlight.legs.length;

      const originLocationCode0 = orderedFlight.itinInfo?.groupDescription.legDescriptions[0]?.departureLocation || '';
      const destLocationCode0 = orderedFlight.itinInfo?.groupDescription.legDescriptions[0]?.arrivalLocation || '';
      const originLocationCode1 = orderedFlight.itinInfo?.groupDescription?.legDescriptions[1]?.departureLocation || '';
      const destLocationCode1 = orderedFlight.itinInfo?.groupDescription?.legDescriptions[1]?.arrivalLocation || '';

      if (ticketBrandInfo < 0) return false;
      const matchedPricingInformation = orderedFlight?.pricingInformation[ticketBrandInfo]?.fare?.passengerInfoList[0] || null;

      // const segment = orderedFlight?.legs[flightIndex]?.segmentExcerpts[segmentIndex] || null;

      const requestBody = {
        itinerary: orderedFlight.legs.map((leg, flightInx) => ({
          departureDate: leg.departureDate,
          originLocationCode: flightInx === 0 ? originLocationCode0 : originLocationCode1,
          destinationLocationCode: flightInx === 0 ? destLocationCode0 : destLocationCode1,
          flights: leg.segmentExcerpts.map((segment, segInx) => {
            const departureDate = `${segment?.departureDate}T${segment?.departure?.time}`;
            const arrivalOnlyDate = new Date(segment?.departureDate);
            arrivalOnlyDate.setDate(arrivalOnlyDate.getDate() + +segment.arrival.dateAdjustment);
            const arrivalDate = `${arrivalOnlyDate.toISOString().slice(0, 10)}T${segment?.arrival?.time}`;

            const { bookingCode } = (matchedPricingInformation?.fareComponents.length === flightCount * leg.segmentExcerpts.length)
              ? matchedPricingInformation?.fareComponents[flightInx + segInx]?.segments[0] || {}
              : matchedPricingInformation?.fareComponents[flightInx]?.segments[segInx] || {};

            return {
              marketingAirlineCode: segment?.carrier?.marketing || '',
              operatingAirlineCode: segment?.carrier?.operating || '',
              departureDateTime: departureDate.slice(0, 19),
              arrivalDateTime: arrivalDate.slice(0, 19),
              originLocationCode: segment?.departure?.airport || '',
              destinationLocationCode: segment?.arrival?.airport || '',
              number: segment.flightNumber,
              classOfService: bookingCode,
            };
          }),
        })),
        passengerComposition: orderedCondition.passengerComposition,
        brandCode: orderedFlight?.pricingInformation[ticketBrandInfo]?.fare.brand?.code || null,
      };

      try {
        context.commit('SET_LOADING_STATE', true);
        const response = await post('BFF', '/operation/aggrSabre/revalidate?excerptOnly=true&sendRaw=false', requestBody, context);

        context.commit('SET_SABRE_FO_BOOKING_REVALIDATE_DATA', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response.data;
      } catch (error) {
        const body = `<p>${JSON.stringify(requestBody)}</p>
        <pre>${error?.response?.data?.title || ''}</pre>
        <pre>${error?.response?.data && JSON.stringify(error?.response?.data)}</pre>`;

        const notification = {
          subject: `Sabre flight revalidate  - ${error?.response?.status || ''}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);
        context.commit('SET_LOADING_STATE', false);
        return null;
      }
    },
    FETCH_SABRE_FO_BOOK_PNR: async (context, payload) => {
      const { device } = context.rootState;
      const { payer, condition } = payload;

      context.commit('SET_SABRE_PAYER_DATA', payer);
      context.commit('SET_SABRE_APPROVAL_CONDITION', condition);
      window.localStorage.setItem('sabrePayerConditionInfo', utf8ToB64(JSON.stringify(payload)));

      const { bookingItineraryInfo, bookingPassengerInfo, baggageInfo, selectedSeatData, priceInfo } = context.state;
      const { orderedFlight, ticketBrandInfo, isFCSupplied } = context.state;

      const itinerary = { ...bookingItineraryInfo };

      itinerary.totalPrice = priceInfo.totalPrice;
      itinerary.currency = priceInfo.brandCurrency;
      itinerary.brandPrice = priceInfo.brandPrice;
      itinerary.brandCode = isFCSupplied ? null : orderedFlight?.pricingInformation[ticketBrandInfo]?.fare?.brand?.code || null;

      const seatSelection = selectedSeatData.length ? selectedSeatData.map((seat) => ({
        passengerRefId: seat.passengerIndex,
        segmentRefId: seat.segmentIndex,
        seatSelection: {
          seatNumber: seat.seatNumber,
          specialPrice: seat.price,
        },
      })) : null;
      const requestBody = {
        fcFlight: isFCSupplied,
        itinerary,
        passengers: bookingPassengerInfo,
        additionalServices: {
          addedBaggages: baggageInfo,
          seatSelectionsInfo: seatSelection ? {
            seatSelections: seatSelection,
            specialPriceAmount: priceInfo.seat,
          } : null,
        },
        payerInfo: payer,
        acceptedTerms: true,
        bypassPayment: true,
        paymentInfo: {
          successUrl: `${window.location.origin}/sabre-fo-redirect-verified`,
          failureUrl: window.location.origin,
          cssUrl: `${window.location.origin}/assets/css/styleVerify.css`,
        },
        additionalInfo: {
          agentScreenResolultion: device,
          packageId: Number(orderedFlight.id.split('-')[1]),
        },
      };

      try {
        context.commit('SET_LOADING_STATE', true);
        const response = await post('BFF', '/operation/aggrSabre/book', requestBody, context);
        const { data } = response;

        if (data?.error?.message) {
          context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', data?.error?.message || '');
        } else {
          context.commit('SET_SABRE_BOOKING_PAYMENT_URL', data.paymentUrl);
          context.commit('SET_SABRE_BOOKING_PNR', data.pnr);
          context.commit('SET_SABRE_BOOKING_TRANS_ID', data.bookingTransId);
          context.commit('SET_SABRE_FO_BOOKING_FLIGHT_INFO', data);
          // context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', `PNR: ${data.pnr}`);

          window.localStorage.setItem('sabrePaymentUrlInfo', utf8ToB64(JSON.stringify(data)));
        }
        context.commit('SET_LOADING_STATE', false);
        return data;
      } catch (error) {
        const body = `<p>${JSON.stringify(requestBody)}</p>
        <pre>${error?.response?.data?.title || ''}</pre>
        <pre>${error?.response?.data && JSON.stringify(error?.response?.data)}</pre>`;

        const notification = {
          subject: `Sabre book flight - ${error?.response?.status || ''}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);
        context.commit('SET_LOADING_STATE', false);
        return null;
      }
    },
    FETCH_SABRE_FO_NOTIFY_CARD_COMPLETION: async (context) => {
      const { bookingTransId } = context.state;
      if (!bookingTransId) {
        context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'The Booking Trans ID is Missing.');
        return;
      }
      try {
        context.commit('SET_LOADING_STATE', true);
        const response = await post('BFF', `/operation/aggrSabre/cardCompleted/${bookingTransId}`, null, context);
        const { data } = response;
        if (data?.error?.message) {
          context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', `${data?.error?.code || ''}: ${data?.error?.message || ''}`);
        } else {
          context.commit('SET_SABRE_BOOKING_CARD_COMPLETION', data);
          context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', `Sabre PNR: ${data.sabrePnrNumber}, PNR: ${data.pnr} \n ${data.status.code}: ${data.status.description}`);
        }
        context.commit('SET_LOADING_STATE', false);
      } catch (error) {
        const body = `
        <pre>${error?.response?.data?.title || ''}</pre>
        <pre>${error?.response?.data && JSON.stringify(error?.response?.data)}</pre>`;

        const notification = {
          subject: `Sabre flight notify card completion - ${error?.response?.status || ''}`,
          body,
        };
        context.dispatch('NOTIFY_ADMIN', notification);
        context.commit('SET_LOADING_STATE', false);
      }
    },
    TAKE_OUT_SABRE_PAYMENT_URL_INFO: (context) => {
      const data = window.localStorage.getItem('sabrePaymentUrlInfo');
      const parsingData = data ? JSON.parse(b64ToUtf8(data)) : null;

      context.commit('SET_SABRE_BOOKING_PAYMENT_URL', parsingData?.paymentUrl || '');
      context.commit('SET_SABRE_BOOKING_PNR', parsingData?.pnr || '');
      context.commit('SET_SABRE_BOOKING_TRANS_ID', parsingData?.bookingTransId || null);
      context.commit('SET_SABRE_FO_BOOKING_FLIGHT_INFO', parsingData);
    },
  },
};
