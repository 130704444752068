const { VUE_APP_MAIN_DOMAIN, VUE_APP_SPEEDSIZE_DOMAIN, VUE_APP_SPEEDSIZE_KEY } = process.env;

const speedSizeImageUrl = {
  data() {
    return {
      speedSizeDomain: `${VUE_APP_SPEEDSIZE_DOMAIN}/${VUE_APP_SPEEDSIZE_KEY}/${VUE_APP_MAIN_DOMAIN}`,
    };
  },
};

export default speedSizeImageUrl;
