import { mapGetters } from 'vuex';

const bannerImageDestination = {
  computed: {
    ...mapGetters({
      imagesData: 'GET_BANNER_IMAGES',
      destinationImages: 'GET_DESTINATION_IMAGES',
      bannerImageDest: 'GET_BANNER_IMAGE_DEST',
      isLanding: 'GET_IS_LANDING_PAGE',
      isArea: 'GET_IS_AREA_PAGE',
      device: 'GET_DEVICE',
    }),
    categoryId() {
      return this.$route.query.categoryId;
    },
    bannerImage() {
      const { name } = this.$route;
      let dest = '';
      let imageLink = '';
      switch (name) {
        case 'home':
          if (this.isLanding) {
            imageLink = this.bannerImageDest?.imageWithHrefList?.[0]?.image ?? '';
          } else {
            imageLink = '';
          }
          break;
        case 'search-result': case 'search-result-sportpack':
          dest = decodeURIComponent(this.$route.query.dest);
          if (!this.destinationImages || (this.destinationImages && !this.destinationImages.length)) return '';
          if (dest.includes(',')) dest = dest.split(',')[0];
          imageLink = this.destinationImages.find((d) => d.code === dest)?.galleryUrls?.[0] ?? '';
          imageLink = this.bannerImageDest?.imageWithHrefList?.[0]?.image ?? imageLink ?? '';
          break;
        case 'product-page': case 'product-page-web': case 'product-page-old-app': case 'product-page-app':
        case 'flight-only': case 'flight-only-web': case 'flight-only-old-app': case 'flight-only-app':
          imageLink = this.bannerImageDest?.imageWithHrefList?.[0]?.image ?? this.imagesData?.imagesByCode?.productBanner?.[0]?.image ?? '';
          break;
        case 'booking-hotel-flight': case 'booking-hotel-flight-web': case 'booking-hotel-flight-old-app': case 'booking-hotel-flight-app':
        case 'booking-flight': case 'booking-flight-web': case 'booking-flight-old-app': case 'booking-flight-app':
        case 'verifying-card': case 'redirect-verified':
        case 'card-verified': case 'card-verified-web': case 'card-verified-old-app': case 'card-verified-app':
          imageLink = this.bannerImageDest?.imageWithHrefList?.[0]?.image ?? this.imagesData?.imagesByCode?.productBanner?.[0]?.image ?? '';
          break;
        default:
      }
      return imageLink;
    },
  },
  methods: {
    async fetchBannerImageByDestination() {
      const { name, query } = this.$route;
      const deviceSign = this.device === 'desktop' ? '' : 'Mobile';
      let pageType = {}, pageId = '', destCode = '', categoryCode = query.categoryId;
      switch (name) {
        case 'home':
          if (this.isLanding) {
            if (!this.isArea) {
              pageId = 'Landing';
              destCode = query.destination;
              categoryCode = query.dealType;
            } else {
              pageId = 'Area';
              destCode = query.destination;
            }
          }
          break;
        case 'search-result': case 'search-result-sportpack':
          pageId = 'SearchResult';
          destCode = decodeURIComponent(query.dest);
          if (destCode.includes(',')) destCode = destCode.split(',')[0];
          break;
        case 'product-page': case 'product-page-web': case 'product-page-old-app': case 'product-page-app':
          pageId = 'Product';
          destCode = this.product?.destination_1 ?? '';
          break;
        case 'flight-only': case 'flight-only-web': case 'flight-only-old-app': case 'flight-only-app':
          pageId = 'Product';
          destCode = this.product?.destination_1 ?? '';
          break;
        case 'booking-hotel-flight': case 'booking-hotel-flight-web': case 'booking-hotel-flight-old-app': case 'booking-hotel-flight-app':
        case 'booking-flight': case 'booking-flight-web': case 'booking-flight-old-app': case 'booking-flight-app':
        case 'verifying-card': case 'redirect-verified':
        case 'card-verified': case 'card-verified-web': case 'card-verified-old-app': case 'card-verified-app':
          pageId = 'Booking';
          destCode = this.product?.destination_1 ?? '';
          categoryCode = this.product.category.code;
          break;
        case 'hotel-list':
          pageId = 'HotelList';
          destCode = query.dest;
          break;
        default:
      }
      if (name !== 'destination-list') {
        if (!this.isLanding) {
          if (categoryCode === 'Organize_tour_packages') {
            pageType = {
              page: `Org${pageId}${deviceSign}/${destCode}`,
            };
          } else if (categoryCode === 'vacation_pack') {
            pageType = {
              page: `FH${pageId}${deviceSign}/${destCode}`,
            };
          } else if (categoryCode === 'Flight_Only') {
            pageType = {
              page: `FO${pageId}${deviceSign}/${destCode}`,
            };
          } else if (categoryCode === 'sport_pack') {
            pageType = {
              page: `SP${pageId}${deviceSign}${destCode}`,
            };
          }
          await this.$store.dispatch('FETCH_BANNER_IMAGE_DESTINATION', pageType);
        } else if (!this.isArea) {
          pageType = {
            page: `Org${pageId}${deviceSign}/${destCode}`,
          };
          await this.$store.dispatch('FETCH_BANNER_IMAGE_DESTINATION', pageType);
          pageType = {
            page: `FH${pageId}${deviceSign}/${destCode}`,
          };
          await this.$store.dispatch('FETCH_BANNER_IMAGE_DESTINATION', pageType);
          pageType = {
            page: `FO${pageId}${deviceSign}/${destCode}`,
          };
          await this.$store.dispatch('FETCH_BANNER_IMAGE_DESTINATION', pageType);
        } else {
          pageType = {
            page: `DA${pageId}${deviceSign}/${destCode}`,
          };
          await this.$store.dispatch('FETCH_BANNER_IMAGE_DESTINATION', pageType);
        }
      }
      await this.$store.dispatch('FETCH_BANNER_IMAGES', { page: 'product' });
    },
  },

};

export default bannerImageDestination;
