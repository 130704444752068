import dayjs from 'dayjs';

function getId(pDeal) {
  return pDeal?.id ?? '';
}
function getLinkImageDeal(pDeal) {
  return pDeal?.image ?? '';
}
function getTitleDeal(pDeal) {
  // return `${pDeal?.title} ${pDeal?.hotelInfo?.address}`;
  return pDeal?.title ?? '';
}
function getSubtitleDeal(pDeal) {
  return pDeal?.subtitle ?? '';
}
function getPriceDeal(pDeal) {
  return pDeal?.price ?? '';
}
function getIsTransfer(pDeal) {
  return pDeal?.transfer ?? false;
}
function getBaggage(pDeal) {
  return pDeal?.baggage ?? '';
}
function getType(pDeal) {
  return pDeal?.type ?? '';
}
function getTitleRemark(pDeal) {
  return pDeal?.remark?.title ?? '';
}
function getBasis(pDeal) {
  const listBasis = Object.entries(pDeal?.translations?.basisName ?? {});
  return listBasis;
}
function getTabs(pDeal) {
  return pDeal?.tabs ?? [];
}
function getTitleTab(idx, pDeal) {
  return pDeal?.tabs?.[idx]?.title ?? '';
}
function getLinkTab(idx, pDeal) {
  if(pDeal === null) return '';
  let {host, protocol} = window.location;
  const originLink = `${pDeal?.tabs?.[idx].link}&category=${pDeal?.packageCategory ?? ''}` ?? '';
  let originUrl = new URL(originLink);

  originUrl.protocol = protocol;
  originUrl.host = host;

  return originUrl.toString();
  // return originLink.replace('https://staging-frontend.vercel.app', 'https://bonuaf-app.vercel.app');
}
function getLink(pDeal) {
  const link = pDeal?.link ?? '';
  const url = new URL(link);
  return url.pathname + url.search;
}
function getShareLink(pDeal) {
  return pDeal?.share ?? '';
}
function getDestCode(pDeal) {
  return pDeal?.dest ?? '';
}
function getNameDestination(pDestCode, pListDest) {
  return pListDest?.[pDestCode] ?? '';
}
function getCategoryCode(pDeal) {
  return pDeal?.category ?? '';
}
function getNameCategory(pCateCode, pListCategory) {
  const cate = pListCategory?.find((el) => el.code === pCateCode);
  return cate?.title ?? '';
}
function sortDeal(pDeal1, pDeal2) {
  // check priority
  let priority = pDeal1.priority - pDeal2.priority;

  // check duration
  const startDate1 = dayjs(pDeal1.packStartDate),
    endDate1 = dayjs(pDeal1.packEndDate),
    startDate2 = dayjs(pDeal2.packStartDate),
    endDate2 = dayjs(pDeal2.packEndDate);
  if (priority === 0) {
    priority = startDate1.diff(startDate2, 'day');
  }
  if (priority === 0) {
    const duration1 = endDate1.diff(startDate1, 'day'), duration2 = endDate2.diff(startDate2, 'day');
    priority = duration1 - duration2;
  }

  // check price
  if (priority === 0) {
    priority = pDeal1.price_int - pDeal2.price_int;
  }

  return priority;
}
function getPackageCategory(pDeal) {
  return pDeal?.packageCategory ?? '';
}

function getFormatDate(date, format = 'DD/MM/YYYY') {
  return dayjs(date).format(format);
}

export {
  getId,
  getLinkImageDeal,
  getTitleDeal,
  getSubtitleDeal,
  getPriceDeal,
  getIsTransfer,
  getBaggage,
  getType,
  getTitleRemark,
  getBasis,
  getTabs,
  getTitleTab,
  getLinkTab,
  getLink,
  getShareLink,
  getDestCode,
  sortDeal,
  getNameDestination,
  getCategoryCode,
  getNameCategory,
  getPackageCategory,
  getFormatDate,
};
